export const prodObj1 = {
    text: "White refined cane sugar ICUMSA 45 shall conform to the following specifications and/or international standard specifications, fit for human consumption: \n - Polarization 99.80° minimum \n - Ash content 0.04% maximum by weight \n - Moisture 0.04% maximum by weight - Magnetic particles Mg/kg 4 \n - Solubility 100% dry & free flowing \n - Granulation Fine standard \n - Icumsa 45 rbu icumsa, attenuation - Max as 1ppm \n - Max os 2ppm \n - Max cu 3ppm \n - Colour Sparking white \n - Sediments None \n - So Mg/kg 20 \n - Reducing sugar 0.010% maximum in dry mass - Radiation Normal w/0 presence of \n - Cesium/iodine so2 Certified \n - Substance Solid, crystal \n - Smell Free of any smell \n - Hpn staph aureus Per 1 gram-nil \n - Microbiological Pathogenic bacteria including limits salmonella per 1 gram-nil bacillus per 1 gram–nil \n - Pesticide traces Maximum mg/kg basis-DDT 0.005-photocin 0.01-hexachloran-gamma \n - Crop Recent crop \n - Phytosanitary certificate of no radiation, no virus, no insect parts, no rodent excrement, no husks and no poisonous matter. Product is suitable for human consumption latest crop sugar should be extracted from Sound, unfermented and undecayed sugar cane sugar should be clean free from any foreign matter, taste or odor and have the sweet taste characteristic of white sugar whether dry or in solution."
};

export const prodObj2 = {
    text: "Brown Raw Cane Sugar ICUMSA 600 shall conform to the following specifications and/or international standard specifications, fit for human consumption: \n - 150 RBU max. \n - Polarization: 97.8° to 99.2° minimum \n - Ash content: 0.15% maximum by weight \n - Moisture: 0.15% maximum by weight \n - Magnetic particles: Mg/kg 4 \n - Solubility: 90% dry & free flowing \n - Granulation: 6mm medium size \n - Icumsa 1200 rbu icumsa, attenuation \n - Max AS: 5ppm \n - Max PS: 2ppm \n - Max CU: 3ppm \n - Colour: Brown \n - Sediments: None \n - So2: Mg/kg 20 \n - Reducing sugar: 0.05% maximum in dry mass \n - Radiation: Normal w/0 presence of \n - Cesium/iodine: So2 Certified \n - Substance: Solid Crystal \n - Smell: Typical and specific for sugar \n - Tolerance: 5% (+/-) \n - Npn staph aureus: Per 1 gram-nil \n - Microbiological Pathogenic: bacteria including limits salmonella per 1 gram-nil bacillus per 1 gram \n – NIL \n - Pesticide traces: Maximum mg/kg basis DDT 0.005 mg/kg \n - Photocin: 0.01 mg/kg \n - Hexachloran-gamma: 0.005 mg/kg \n - Crop: Recent crop \n - Phytosanitary certificate of no radiation, no virus, no insect parts, no rodent excrement, no husks and no poisonous matter. \n - Product is suitable for human consumption latest crop sugar should be extracted from Sound, unfermented and undecayed raw cane sugar should be clean free from any foreign matter, taste or odor and have the sweet taste characteristic of raw cane sugar whether dry or in solution."
};

export const prodObj3 = {
    text: "Brown Raw Cane Sugar ICUMSA 1200 shall conform to the following specifications and/or international standard specifications, fit for human consumption: \n - Polarization: 99.80° minimum \n - Ash content: 0.04% maximum by weight \n - Moisture: 0.09% maximum by weight - Magnetic particles: Mg/kg 4 \n - Solubility: 100% dry & free flowing \n - Granulation: Fine  \n - Icumsa 1200 rbu icumsa, attenuation - Max as 1ppm \n - Max os 2ppm \n - Max cu 3ppm  \n - Colour: Brown \n - Sediments: None \n - So2: Mg/kg 20 \n - Reducing sugar: 0.05% maximum in dry mass \n - Radiation: Normal w/0 presence of \n - Cesium/iodine: So2 Certified \n - Substance: Solid Crystal \n - Smell: Typical and specific for sugar \n - Tolerance: 5% (+/-) \n - Npn staph aureus: Per 1 gram-nil \n - Microbiological Pathogenic: bacteria including limits salmonella per 1 gram-nil bacillus per 1 gram – NIL - Pesticide traces: Maximum mg/kg basis \n - DDT 0.005 mg/kg \n - Photocin: 0.01 mg/kg \n - Hexachloran-gamma: 0.005 mg/kg \n - Crop: Recent crop \n - Phytosanitary certificate of no radiation, no virus, no insect parts, no rodent excrement, no husks and no poisonous matter. \n - Product is suitable for human consumption latest crop sugar should be extracted from Sound, unfermented and undecayed raw cane sugar should be clean free from any foreign matter, taste or odor and have the sweet taste characteristic of brown raw cane sugar whether dry or in solution."
};
export const prodObj4 = {
    text: "YELLOW CORN NON-GMO shall conform to the following specifications and/or international standard specifications, fit for human consumption:\n - PRODUCT & GRADE \n - Yellow corn grade 2,\n - NON-GMO Test weight - min. 70 kgs per hl \n - Foreign matter - max. 3% \n - Moisture - max. 13% \n - Other grains - max. 2% \n - Broken - max. 3% \n - Damaged - max. 3% \n - Aflatoxin - max. 20 ppb \n - Admixture - max. 2% Fresh Crop"
};
export const prodObj5 = {
    text: "YELLOW CORN shall conform to the following specifications and/or international standard specifications, fit for human consumption: \n - PRODUCT & GRADE  \n - Yellow corn grade 2, \n - GMO Test weight - min. 70 kgs per hl - Foreign matter - max. 3% \n - Moisture - max. 13% \n - Other grains - max. 2% \n - Broken - max. 3% \n - Damaged - max. 3% \n - Aflatoxin - max. 20 ppb \n - Admixture - max. 2% Fresh Crop "
};
export const prodObj6 = {
    text: "SOYBEAN GMO shall conform to the following specifications and/or international standard specifications, fit for human consumption: - PRODUCT & GRADE \n - Yellow soybeans specifications  \n - Type: GMO yellow soybeans, graded # 2 \n - Test weight: 54 lbs/bu minimum \n - Protein: 35%, minimum \n - Moisture: 13.5% maximum  \n - Splits: 11% maximum \n - Foreign matters: 2% maximum \n - Soybeans, other color: 2% maximum \n - Hear damaged kernels: 0.5% maximum \n - Total damaged kernels: 3.0% maximum \n - Oil content: 18.5% minimum \n - Crop: current"
};
export const prodObj7 = {
    text: " - Moisture : 12% max. \n - Test Weight : 76kg/hl \n - Foreign Matter (Dirt/Dust & Other Non-edible Matters) : 1.5% Max. \n Other Food Grains : 3% Max. \n - Shriveled & Broken Grain : 3% Max. \n - Damaged Grain : 2% Min. \n - Gluten(Wet) : 26% Min. \n - Protein on Dry Basis : 1.5% Min. \n - Falling Number : 250/sec. Min. \n - Live Insects \n - Nil \n - Odour-free and freshly Fumigated "
};
export const prodObj8 = {
    text: " - MOISTURE : 14.00 % MAX \n - RED KERNELS : 2.00 % MAX  \n - BROKEN : 100 % MAX\n - IMMUATURE GRAINS : 2.50 % MAX  \n - YELLOW KERNELS : 1.00 % MAX \n - CHALKY KERNELS : 3.00 % MAX  \n - FOREGN MATTER : 0.10 % MAX  \n - PADDY KERNELS : 0.00 % MAX"
};
export const prodObj9 = {
    text: " - Rice \n - Sortex Praboiled \n - Moisture content 14% Max \n - Red Karnels 2 % Max \n - Damaged 0.50 -Max \n - Immature Grains 2.5 % Max \n - Yellow Kernel 0.50 % Max \n - Chalky Kernels 3.00 % Max \n - Foreign Matter 0.10 % Max \n - Paddy Karnels 0.00 Max \n - Crop latest year available 2022-2023  \n - Pcking: 50kg"
};
export const prodObj10 = {
    text: ""
};